import React from 'react'
import CaseStudyLayout from '../../../components/caseStudyLayout'
import * as styles from './styles.module.css'
import { Helmet } from 'react-helmet'
import { graphql } from 'gatsby'

import heroApp from './img/hero-app.png'
import heroBg from './img/hero-bg.png'
import phonesCutoff from './img/phones-cutoff.png'
import zarkWideLogo from './img/zark-wide-logo.png'
import zark01 from './img/zark-01.png'
import zarkScreens01 from './img/zark-screens-01.png'
import zark02 from './img/zark-02.png'
import zarkScreens02 from './img/zark-screens-02.png'
import zark03 from './img/zark-03.png'

const ZarkParking = ({ data }) => {
  return (
    <>
      <CaseStudyLayout {...data}>
        <Helmet bodyAttributes={{ class: styles.zarkParkingBody }} />

        <div className={styles.zarkParking}>
          <section
            className={styles.heroNew}
            style={{
              backgroundImage: `url('${phonesCutoff}')`,
            }}
          >
            <img alt="" src={heroApp} height="auto" />
            <div className={styles.heroTextContainer}>
              <h1 className={styles.heroHeading}>Zark Parking</h1>
              <h4 className={styles.heroSubheading}>
                UX/UI Design, iOS & Android Development
              </h4>
            </div>
          </section>
          <section className={styles.textContainer}>
            <div className={`${styles.flex} ${styles.container}`}>
              <div className={styles.row}>
                <div className={styles.col3}>
                  <img alt="" src={zarkWideLogo} width="auto" height="auto" />
                </div>
                <div className={styles.col1}></div>
                <div className={`${styles.col8} ${styles.textContent}`}>
                  <p>
                    If you’ve ever lived at an apartment complex, you might be
                    familiar with how frustrating it is to find parking for
                    yourself or your guests. For some apartment complexes,
                    parking issues are among the top three resident complaints.
                  </p>
                  <p>
                    Zark was founded by a team of veteran apartment developers
                    who understand first-hand how troublesome
                    parking-constrained properties are for residents, guests,
                    on-site property staff, and owners. Constructing additional
                    parking spaces at existing properties is cost prohibitive
                    and the Zark team knew that there must be a better way.
                  </p>
                </div>
              </div>
            </div>
          </section>
          <section className={`${styles.textContainer} ${styles.grayBg}`}>
            <div className={`${styles.flex} ${styles.container}`}>
              <div className={styles.row}>
                <div className={`${styles.col7} ${styles.textContent}`}>
                  <h3>Challenge</h3>
                  <p>
                    We understood from the onset that this product needed to be
                    easy to use for all parties involved.
                  </p>
                  <ul>
                    <li>
                      Residents needed to be able to seamlessly reserve parking
                      spaces near their unit
                    </li>
                    <li>
                      Property staff needed to be able to manage their complex’s
                      parking inventory without it being a burden
                    </li>
                    <li>
                      Property owners needed the confidence that partnering with
                      Zark would be a smooth and cost-effective onboarding
                      process that truly solved the parking problems at their
                      complex
                    </li>
                  </ul>
                </div>
                <div className={styles.col1}></div>
                <div className={`${styles.col4} ${styles.imageContainer}`}>
                  <img alt="" src={zark01} width="100%" height="auto" />
                </div>
              </div>
            </div>
          </section>
          <section className={`${styles.mockupContainer} ${styles.spacer}`}>
            <img alt="" src={zarkScreens01} />
            <div className={styles.scrollIcon}>
              <svg width="45px" height="50px" viewBox="0 0 96 99" version="1.1">
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Group"
                    transform="translate(0.796268, 0.001780)"
                    fill="#333447"
                    fillRule="nonzero"
                  >
                    <path
                      d="M31.3069052,13 C29.4069052,11.1 28.3069052,8.6 28.0069052,6 L15.6069052,18.5 L11.9069052,14.8 C10.7069052,13.6 8.70690515,14.3 8.60690515,16 L7.00690515,31.6 C6.90690515,32.8 7.90690515,33.9 9.10690515,33.7 L24.7069052,32.2 C26.4069052,32 27.1069052,30 25.9069052,28.9 L22.2069052,25.2 L32.8069052,14.6 L31.3069052,13 Z"
                      id="Path"
                      transform="translate(19.903453, 19.861827) rotate(45.000000) translate(-19.903453, -19.861827) "
                    ></path>
                    <path
                      d="M61.4584234,31.2825216 L63.0584234,32.8825216 L73.6584234,22.1825216 L77.3584234,25.8825216 C78.5584234,27.0825216 80.5584234,26.3825216 80.6584234,24.6825216 L82.1584234,9.08252159 C82.2584234,7.88252159 81.2584234,6.78252159 80.0584234,6.98252159 L64.4584234,8.48252159 C62.7584234,8.68252159 62.0584234,10.6825216 63.2584234,11.7825216 L66.9584234,15.4825216 L54.5584234,27.9825216 C57.1584234,28.1825216 59.6584234,29.3825216 61.4584234,31.2825216 Z"
                      id="Path"
                      transform="translate(68.361876, 19.920694) rotate(45.000000) translate(-68.361876, -19.920694) "
                    ></path>
                    <path
                      d="M80.2893936,42.8291383 L69.2893936,31.8291383 C67.0893936,29.6291383 63.4893936,29.6291383 61.2893936,31.8291383 C59.8893936,33.2291383 59.3893936,35.2291383 59.7893936,37.1291383 L59.6893936,37.0291383 C57.4893936,34.8291383 53.8893936,34.8291383 51.6893936,37.0291383 C50.2893936,38.4291383 49.7893936,40.4291383 50.1893936,42.3291383 L50.0893936,42.2291383 C47.8893936,40.0291383 44.2893936,40.0291383 42.0893936,42.2291383 C40.6893936,43.6291383 40.1893936,45.6291383 40.5893936,47.5291383 L27.0893936,34.0291383 C25.9893936,32.9291383 24.5893936,32.3291383 23.0893936,32.3291383 C21.5893936,32.3291383 20.1893936,32.9291383 19.0893936,34.0291383 C19.0893936,34.0291383 19.0893936,34.0291383 19.0893936,34.0291383 C16.8893936,36.2291383 16.8893936,39.8291383 19.0893936,42.0291383 L43.8893936,66.7291383 C44.0893936,66.9291383 44.1893936,67.3291383 44.0893936,67.6291383 C43.9893936,67.9291383 43.6893936,68.1291383 43.3893936,68.1291383 L35.2893936,68.1291383 C32.5893936,68.1291383 30.1893936,70.1291383 29.6893936,72.7291383 C29.3893936,74.3291383 29.7893936,75.9291383 30.6893936,77.2291383 C31.6893936,78.5291383 33.0893936,79.3291383 34.7893936,79.5291383 L57.0893936,81.6291383 C63.3893936,82.2291383 69.5893936,80.0291383 74.0893936,75.5291383 L80.3893936,69.2291383 C83.8893936,65.7291383 85.8893936,61.0291383 85.8893936,56.0291383 C85.8893936,51.0291383 83.8893936,46.4291383 80.2893936,42.8291383 Z"
                      id="Path"
                      transform="translate(51.664394, 55.952665) rotate(45.000000) translate(-51.664394, -55.952665) "
                    ></path>
                  </g>
                </g>
              </svg>
            </div>
            )
          </section>
          <section className={`${styles.textContainer} ${styles.grayBg}`}>
            <div className={`${styles.flex} ${styles.container}`}>
              <div className={styles.row}>
                <div className={`${styles.col7} ${styles.textContent}`}>
                  <h3>Results</h3>
                  <p>We knocked it out of the Zark.</p>
                  <p>
                    Zark is quickly expanding into apartment complexes and
                    residents couldn’t be more excited that their parking woes
                    are a thing of the past.
                  </p>
                  <p>
                    The Zark team has been a joy to work with and we’re
                    continuing to build new features that will set them apart
                    from others and enable them to scale.
                  </p>
                  <p>
                    The feedback received from residents, guests, on-site
                    property staff, and owners is overwhelmingly positive. Don’t
                    take it from us, see what people are saying about Zark!
                  </p>
                </div>
                <div className={styles.col1}></div>
                <div className={`${styles.col4} ${styles.imageContainer}`}>
                  <img alt="" src={zark02} width="100%" height="auto" />
                </div>
              </div>
            </div>
          </section>
          <section
            className={`${styles.mockupContainer} ${styles.spacer}`}
            style={{
              background:
                'linear-gradient(to bottom, #ffffff, #ffffff 25%, #2389d6 25%, #2389d6 75%, #ffffff 75%)',
            }}
          >
            <img alt="" src={zarkScreens02} />
            <div className={styles.scrollIcon}>
              <svg width="45px" height="50px" viewBox="0 0 96 99" version="1.1">
                <g
                  id="Page-1"
                  stroke="none"
                  strokeWidth="1"
                  fill="none"
                  fillRule="evenodd"
                >
                  <g
                    id="Group"
                    transform="translate(0.796268, 0.001780)"
                    fill="#333447"
                    fillRule="nonzero"
                  >
                    <path
                      d="M31.3069052,13 C29.4069052,11.1 28.3069052,8.6 28.0069052,6 L15.6069052,18.5 L11.9069052,14.8 C10.7069052,13.6 8.70690515,14.3 8.60690515,16 L7.00690515,31.6 C6.90690515,32.8 7.90690515,33.9 9.10690515,33.7 L24.7069052,32.2 C26.4069052,32 27.1069052,30 25.9069052,28.9 L22.2069052,25.2 L32.8069052,14.6 L31.3069052,13 Z"
                      id="Path"
                      transform="translate(19.903453, 19.861827) rotate(45.000000) translate(-19.903453, -19.861827) "
                    ></path>
                    <path
                      d="M61.4584234,31.2825216 L63.0584234,32.8825216 L73.6584234,22.1825216 L77.3584234,25.8825216 C78.5584234,27.0825216 80.5584234,26.3825216 80.6584234,24.6825216 L82.1584234,9.08252159 C82.2584234,7.88252159 81.2584234,6.78252159 80.0584234,6.98252159 L64.4584234,8.48252159 C62.7584234,8.68252159 62.0584234,10.6825216 63.2584234,11.7825216 L66.9584234,15.4825216 L54.5584234,27.9825216 C57.1584234,28.1825216 59.6584234,29.3825216 61.4584234,31.2825216 Z"
                      id="Path"
                      transform="translate(68.361876, 19.920694) rotate(45.000000) translate(-68.361876, -19.920694) "
                    ></path>
                    <path
                      d="M80.2893936,42.8291383 L69.2893936,31.8291383 C67.0893936,29.6291383 63.4893936,29.6291383 61.2893936,31.8291383 C59.8893936,33.2291383 59.3893936,35.2291383 59.7893936,37.1291383 L59.6893936,37.0291383 C57.4893936,34.8291383 53.8893936,34.8291383 51.6893936,37.0291383 C50.2893936,38.4291383 49.7893936,40.4291383 50.1893936,42.3291383 L50.0893936,42.2291383 C47.8893936,40.0291383 44.2893936,40.0291383 42.0893936,42.2291383 C40.6893936,43.6291383 40.1893936,45.6291383 40.5893936,47.5291383 L27.0893936,34.0291383 C25.9893936,32.9291383 24.5893936,32.3291383 23.0893936,32.3291383 C21.5893936,32.3291383 20.1893936,32.9291383 19.0893936,34.0291383 C19.0893936,34.0291383 19.0893936,34.0291383 19.0893936,34.0291383 C16.8893936,36.2291383 16.8893936,39.8291383 19.0893936,42.0291383 L43.8893936,66.7291383 C44.0893936,66.9291383 44.1893936,67.3291383 44.0893936,67.6291383 C43.9893936,67.9291383 43.6893936,68.1291383 43.3893936,68.1291383 L35.2893936,68.1291383 C32.5893936,68.1291383 30.1893936,70.1291383 29.6893936,72.7291383 C29.3893936,74.3291383 29.7893936,75.9291383 30.6893936,77.2291383 C31.6893936,78.5291383 33.0893936,79.3291383 34.7893936,79.5291383 L57.0893936,81.6291383 C63.3893936,82.2291383 69.5893936,80.0291383 74.0893936,75.5291383 L80.3893936,69.2291383 C83.8893936,65.7291383 85.8893936,61.0291383 85.8893936,56.0291383 C85.8893936,51.0291383 83.8893936,46.4291383 80.2893936,42.8291383 Z"
                      id="Path"
                      transform="translate(51.664394, 55.952665) rotate(45.000000) translate(-51.664394, -55.952665) "
                    ></path>
                  </g>
                </g>
              </svg>
            </div>
            )
          </section>
          <section
            className={styles.textContainer}
            style={{
              background: `url('${heroBg}') bottom left no-repeat`,
            }}
          >
            <div className={`${styles.flex} ${styles.container}`}>
              <div className={styles.row}>
                <div className={`${styles.col7} ${styles.textContent}`}>
                  <h3>Testimonials</h3>
                  <p>
                    "Our residents consider parking to be one of our properties’
                    most valuable amenities. Zark Parking has provided us with
                    an affordable, quick, and convenient way to offer residents
                    a parking solution, and our communities are happier for it!"
                  </p>
                  <p>
                    <strong>
                      Mike Clow, Greystar Real Estate Partners – Executive
                      Director, Real Estate
                    </strong>
                  </p>

                  <p>
                    "The Zark Parking app is probably one of the best features
                    this apartment complex has to offer. It’s really easy to use
                    and really solves the problem of not enough parking space
                    for the residents."
                  </p>
                  <p>
                    <strong>Marcos Ayala - Resident</strong>
                  </p>

                  <p>
                    "I’m a little peeved this app wasn’t developed sooner. All
                    the times I played merry go round trying to find one open
                    spot! So excited to have this for when family and friends
                    come over too. What a great concept!"
                  </p>
                  <p>
                    <strong>Ashley Nestor - Resident</strong>
                  </p>

                  <p>
                    "Implementing Zark at our community couldn’t have been
                    easier. It’s a very hands-off tool for the management team
                    which was an important piece when considering Zark."
                  </p>
                  <p>
                    <strong>Sarah O’Daniel - Regional Property Manager</strong>
                  </p>
                </div>
                <div className={styles.col1}></div>
                <div className={`${styles.col4} ${styles.imageContainer}`}>
                  <img alt="" src={zark03} width="100%" height="auto" />
                </div>
              </div>
            </div>
          </section>
          <section className={styles.textContainer}>
            <div
              className={`${styles.flex} ${styles.container} ${styles.column} ${styles.colors} `}
            >
              <div className={styles.row}>
                <div className={`${styles.col12} ${styles.textContent}`}>
                  <h3>Color Palette</h3>
                </div>
              </div>
              <div className={styles.row}>
                <div className={`${styles.col12} ${styles.flex}`}>
                  <div
                    className={`${styles.colorBox} ${styles.boxHalf} ${styles.brandBlue}`}
                  >
                    <div className={styles.colorName}>Steel Blue</div>
                    <div className={styles.colorHex}>#3F72AF</div>
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <div className={`${styles.col12} ${styles.flex}`}>
                  <div
                    className={`${styles.colorBox} ${styles.boxHalf} ${styles.brandLightblue}`}
                  >
                    <div className={styles.colorName}>Summer Sky</div>
                    <div className={styles.colorHex}>#3498DB</div>
                  </div>
                </div>
              </div>
              <div className={styles.row}>
                <div className={`${styles.col6} ${styles.flex}`}>
                  <div
                    className={`${styles.colorBox} ${styles.boxHalf} ${styles.brandDarkblue}`}
                  >
                    <div className={styles.colorName}>Regal Blue</div>
                    <div className={styles.colorHex}>#163150</div>
                  </div>
                </div>
                <div className={`${styles.col6} ${styles.flex}`}>
                  <div
                    className={`${styles.colorBox} ${styles.boxHalf} ${styles.brandRed}`}
                  >
                    <div className={styles.colorName}>Cinnabar</div>
                    <div className={styles.colorHex}>#D84242</div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </CaseStudyLayout>
    </>
  )
}

export default ZarkParking

export const query = graphql`
  query zarkCaseStudyQuery {
    caseStudy: contentfulCaseStudy(slug: { eq: "zark-parking" }) {
      seoTitle
      slug
      title
      excerpt
    }
    caseStudyPage: contentfulPage(name: { eq: "page.caseStudy" }) {
      ...pageFields
    }
    workPage: contentfulPage(name: { eq: "page.work" }) {
      workPageSlug: slug
    }
  }
`
